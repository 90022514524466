import React from "react";

import { useTranslation } from "react-i18next";

import { Visible } from "@Atoms";
import { useTheme } from "@emotion/react";
import { colorPrimary } from "@Styles";
import { Attachment } from "@Types/services/post";
import AttachmentImage from "../AttachmentImage";
import { Heading } from "../Heading";
import Sticker from "../Sticker";
import { Body, BodyLg, BodySm, Link } from "../typography";
import {
  centerOnMediumSizeStyles,
  containerStyles,
  contentStyles,
  headerStyles,
  imageContainerStyle,
  linkContainerStyle,
  quoteAuthorDescriptionStyle,
  quoteAuthorTitleStyle,
  quoteContainerStyles,
  stickerContainerStyle,
} from "./styleTestimonial";

interface TestimonialProps {
  /** Attachment that will be used as image. */
  attachment?: Attachment;
  /** Alternative text for the image. */
  alt?: string;
  /** Quote. */
  quote: string;
  /** Author of the quote. */
  quoteAuthor: string;
  /** Author description. */
  quoteAuthorDescription?: string;
  /** Content of the banner. */
  content?: string | React.ReactNode;
  /** Label for tag. */
  tag?: string;
  /** URL for the call-to-action. */
  link?: string;
}

/**
 * Quote with author, excerpt and a call-to-action to read more
 */
const Testimonial = ({
  alt,
  attachment,
  link,
  content,
  quote,
  quoteAuthor,
  quoteAuthorDescription,
  tag,
}: TestimonialProps) => {
  const { tone } = useTheme();

  const { t } = useTranslation();

  return (
    <div css={containerStyles}>
      <div css={headerStyles}>
        <div css={imageContainerStyle}>
          <AttachmentImage
            alt={alt}
            attachment={attachment}
            size="thumb"
          />
        </div>

        <div css={quoteContainerStyles}>
          <Visible when={tag?.length}>
            <div css={stickerContainerStyle}>
              <Sticker
                label={tag || ""}
                variant="secondary"
              />
            </div>
          </Visible>

          <Heading
            scale={5}
            color="primary"
            lineClamp={{ default: 2, lockHeight: true }}
          >
            {quote}
          </Heading>

          <div css={centerOnMediumSizeStyles}>
            <BodyLg
              lineClamp={{ default: 2, md: 2 }}
              css={quoteAuthorTitleStyle}
            >
              <strong>{quoteAuthor}</strong>
            </BodyLg>

            <BodySm
              lineClamp={{ default: 1, md: 1 }}
              css={quoteAuthorDescriptionStyle}
            >
              {quoteAuthorDescription ?? ""}
            </BodySm>
          </div>
        </div>
      </div>

      <Visible when={!!content}>
        <Body
          css={[contentStyles]}
          lineClamp={{ default: 4 }}
        >
          {content}
        </Body>
      </Visible>

      <Visible when={!!link}>
        <div css={linkContainerStyle}>
          <span css={colorPrimary(tone)}>&gt;&nbsp;</span>

          <Link href={link}>{t("common.more.read")}</Link>
        </div>
      </Visible>
    </div>
  );
};
export default Testimonial;
