import type { CSSObject } from "@emotion/react";
import {
  displayFlex,
  elevation3,
  gapLg,
  gapMd,
  gapXl,
  marginBottomZero,
  marginZero,
  paddingXMdXl,
  paddingXXsMd,
  paddingYXl,
  paddingZero,
} from "@Styles";

import { breakpointDown, breakpointUp, colors } from "@Variables";

const containerStyles: CSSObject[] = [
  elevation3,
  gapLg,
  paddingXXsMd,
  paddingXMdXl,
  paddingYXl,
  {
    display: "flex",
    flexDirection: "column",
    backgroundColor: colors.white,
  },
];

const headerStyles: CSSObject[] = [
  displayFlex,
  gapLg,
  {
    flexDirection: "column",
    alignItems: "center",
    [breakpointUp["md"]]: {
      flexDirection: "row",
    },
  },
];

const imageContainerStyle: CSSObject = {
  borderRadius: "50%",
  flexGrow: 0,
  flexShrink: 0,
  height: "10rem",
  overflow: "hidden",
  width: "10rem",
};

const contentStyles: CSSObject[] = [marginBottomZero];

const centerOnMediumSizeStyles: CSSObject = {
  ...displayFlex,

  flexDirection: "column",

  [breakpointDown["md"]]: {
    textAlign: "center",
  },
};
const quoteContainerStyles: CSSObject[] = [gapMd, centerOnMediumSizeStyles];

const quoteAuthorTitleStyle: CSSObject[] = [
  marginBottomZero,
  { display: "flex", alignItems: "center" },
];
const quoteAuthorDescriptionStyle: CSSObject[] = [
  marginZero,
  paddingZero,
  centerOnMediumSizeStyles,
];

const linkContainerStyle: CSSObject = {
  display: "flex",
  justifyContent: "flex-end",
};

const stickerContainerStyle: CSSObject[] = [
  gapXl,
  displayFlex,
  {
    justifyContent: "center",
    [breakpointUp["md"]]: {
      justifyContent: "start",
    },
  },
];

export const quoteHeadBodyStyles: CSSObject[] = [
  {
    margin: "auto",
    [breakpointDown["md"]]: {
      textAlign: "center",
    },
  },
];

export {
  centerOnMediumSizeStyles,
  containerStyles,
  contentStyles,
  headerStyles,
  imageContainerStyle,
  linkContainerStyle,
  quoteAuthorDescriptionStyle,
  quoteAuthorTitleStyle,
  quoteContainerStyles,
  stickerContainerStyle,
};
