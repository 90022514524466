import React, { useContext, useEffect, useState } from "react";

import { CSSObject, useTheme } from "@emotion/react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faArrowDownToBracket,
  faHandFist,
  faPartyHorn,
  faShareNodes,
} from "@fortawesome/pro-regular-svg-icons";
import { faBoxHeart, faCheckDouble } from "@fortawesome/pro-solid-svg-icons";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import fileDownload from "js-file-download";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import { Visible } from "@Atoms";
import {
  Button,
  ButtonContainer,
  Heading,
  Headline,
  Placeholder,
  UserForm,
  UserFormFields,
} from "@Components";
import { UserContext } from "@Contexts/UserContext";
import { marginBottomLg } from "@Styles/spacers";
import getDonationUrl from "@Stylize/helpers/getDonationUrl";
import { ValidationMessage } from "@Stylize/helpers/validation";
import { Signed } from "@Types/services/documents";
import { Signable } from "@Types/services/post";
import api from "@Utils/api";
import { getSignableStatus } from "@Utils/getSignableStatus";
import { getSignatureTarget } from "@Utils/getSignatureTarget";

type SignedFormProps = {
  signable: Signable;
};

type SignedStatus = "notSigned" | "signed" | "ended" | "success";

const icons: { [key in SignedStatus]: IconProp } = {
  notSigned: faHandFist,
  signed: faHandFist,
  ended: faCheckDouble,
  success: faPartyHorn,
};

const SignForm = ({ signable }: SignedFormProps) => {
  const baseTranslationString = "pages.signable.signForm";
  const [downloadLoading, setDownloadLoading] = useState(false);
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const signableStatus = getSignableStatus(signable);
  const [signed, setSigned] = useState<Signed>({} as Signed);
  const [signedStatus, setSignedStatus] = useState("" as SignedStatus);
  const [signer, setSigner] = useState({
    firstName: "",
    lastName: "",
    email: "",
    country: "DE",
    postalCode: "",
    postalCodeDisplay: "",
    location: "",
    locationDisplay: "",
  } as UserFormFields);
  const { tone } = useTheme();
  const { trackEvent } = useMatomo();
  const { isAuth, user } = useContext(UserContext);

  const getTitle = () => {
    let title = `${baseTranslationString}.${signedStatus}.title.${tone}`;
    const options: { signatureTarget?: string; endDate?: string } = {};

    if ("notSigned" == signedStatus) {
      if ("initiative" == signable.type) {
        options.endDate = signable.currentStage?.endDate || "";

        title += options.endDate ? ".withEnd" : ".withTarget";
      }

      options.signatureTarget = new Intl.NumberFormat(i18n.language).format(
        getSignatureTarget(signable),
      );
    }

    return t(title, options);
  };

  const getText = () => {
    let text = `${baseTranslationString}.${signedStatus}.text.${tone}`;

    if ("signed" == signedStatus && "initiative" == signable.type) {
      text +=
        "exportType" in signed && "post" == signed.exportType
          ? ".post"
          : ".download";
    }

    return t(text);
  };

  const downloadDocument = (id: string, setLoadingOff = true) => {
    setDownloadLoading(true);
    api
      .downloadDocument(id)
      .then((response) => {
        fileDownload(response.data, "innn_it.pdf");
      })
      .catch(null)
      .finally(() => setLoadingOff && setDownloadLoading(false));
  };

  const handleDocumentDownload = (event: React.FormEvent<HTMLElement>) => {
    event.preventDefault();

    if (
      "signed" !== signedStatus ||
      "initiative" !== signable.type ||
      !("verified" in signed && signed.verified)
    ) {
      return;
    }

    if ("exportType" in signed && "download" == signed.exportType) {
      downloadDocument(signed._id);
    } else if ("exportType" in signed && "post" == signed.exportType) {
      setDownloadLoading(true);
      api
        .createDocument({
          initiativeId: signable._id,
          initiativeStage: signable.stage,
          firstName: signer.firstName,
          lastName: signer.lastName,
          country: signer.country,
          location: signer.location,
          postalCode: signer.postalCode,
          email: signer.email,
          subscribe: signer.subscribe,
          exportType: "download",
        })
        .then((response) => {
          downloadDocument(response.data._id, false);
        })
        .catch(() => {
          return {
            valid: false,
            message: t("components.userForm.validation.submit.error"),
          };
        })
        .finally(() => setDownloadLoading(false));
    }
  };

  const handleFormSubmit = (): Promise<ValidationMessage> => {
    if (
      "active" == signableStatus &&
      (("petition" == signable.type && "active" == signable.initiativeStatus) ||
        ("initiative" == signable.type &&
          "support" == signable.currentStage.stage))
    ) {
      return api
        .createSignature({
          signableId: signable._id,
          signableModel:
            "petition" == signable.type ? "Petition" : "Initiative",
          initiativeStage: "initiative" == signable.type && signable.stage,
          firstName: signer.firstName,
          lastName: signer.lastName,
          country: signer.country,
          location: signer.location,
          postalCode: signer.postalCode,
          email: signer.email,
          subscribe: signer.subscribe,
        })
        .then((response) => {
          trackEvent({
            category: "signable",
            action: "createSignature",
            customDimensions: [
              {
                id: 1, // Signable ID
                value: signable._id,
              },
              {
                id: 2, // Signable model
                value: signable.type,
              },
              {
                id: 3, // Newsletter opt-in
                value: response.data.subscribe.toString(),
              },
              {
                id: 4, // Logged-in
                value: isAuth ? "true" : "false",
              },
            ],
          });

          navigate(`/${signable.name}/2`, {
            state: { signable, signed: response.data, signer },
          });
          return { valid: true, message: "" };
        })
        .catch(() => {
          return {
            valid: false,
            message: t("components.userForm.validation.submit.error"),
          };
        });
    } else if ("active" == signableStatus && "initiative" == signable.type) {
      const promise = Promise.resolve();
      return promise.then(() => {
        trackEvent({
          category: "signable",
          action: "startDocument",
          customDimensions: [
            {
              id: 1, // Signable ID
              value: signable._id,
            },
            {
              id: 2, // Signable model
              value: signable.type,
            },
            {
              id: 3, // Newsletter opt-in
              value: signer.subscribe ? "true" : "false",
            },
          ],
        });

        navigate(`/${signable.name}/1`, {
          state: { signable, signer },
        });
        return { valid: true, message: "" };
      });
    } else {
      const promise = Promise.resolve();
      return promise.then(() => {
        return {
          valid: false,
          message: t("components.userForm.validation.submit.error"),
        };
      });
    }
  };

  const parseSignedResponse = (response: Signed[]) => {
    if (response.length > 0) {
      setSignedStatus("signed");
      setSigned(response[0]);
    } else {
      setSignedStatus("notSigned");
    }
  };

  useEffect(() => {
    if ("active" !== signableStatus) {
      setSignedStatus("canceled" == signableStatus ? "ended" : signableStatus);
    } else {
      if (isAuth) {
        const params = { signableId: signable._id, signer: "me" };
        if ("initiative" === signable.type) {
          api
            .getDocuments(params)
            .then((response) => {
              parseSignedResponse(response.data);
            })
            .catch(() => null);
        } else if ("petition" === signable.type) {
          api
            .getSignatures(params)
            .then((response) => {
              parseSignedResponse(response.data);
            })
            .catch(() => parseSignedResponse([]));
        }
      } else {
        setSignedStatus("notSigned");
      }
    }
  }, [isAuth, signable, signableStatus]);
  const headlineContainerStyle: CSSObject[] = [marginBottomLg];

  useEffect(() => {
    if (isAuth && user) {
      const signer = {
        firstName: user.firstName || "",
        lastName: user.lastName || "",
        email: user.email || "",
        country: user.country || "",
        postalCode: user.postalCode || "",
        postalCodeDisplay: "",
        location: user.location || "",
      };
      setSigner(signer);
    }
  }, [isAuth, user]);

  const donationUrl = getDonationUrl({
    context: signable.type,
    source: "signablePage",
    signableId: signable._id,
    donor: {
      firstName: user?.firstName || "",
      lastName: user?.lastName || "",
      email: user?.email || "",
    } as UserFormFields,
  });

  return (
    <div>
      {!signedStatus ? (
        <>
          <Heading scale={1}>
            <Placeholder />
          </Heading>
          <p>
            <Placeholder lines={3} />
          </p>
        </>
      ) : (
        <>
          <div css={headlineContainerStyle}>
            <Headline
              icon={icons[signedStatus]}
              title={getTitle()}
              text={getText()}
            />
          </div>
          {signedStatus === "signed" ? (
            <div>
              <p>{t(`${baseTranslationString}.actions.title`)}</p>
              <ButtonContainer
                align={{ default: "justify" }}
                direction={{ default: "vertical" }}
              >
                {"initiative" == signable.type ? (
                  <Button
                    icon={faArrowDownToBracket}
                    label={t(`${baseTranslationString}.actions.download`)}
                    loading={downloadLoading}
                    onClick={handleDocumentDownload}
                    tone={tone}
                    variant="primary"
                  />
                ) : (
                  <Button
                    as={Link}
                    icon={faBoxHeart}
                    label={t(`${baseTranslationString}.actions.donate`)}
                    tone={tone}
                    to={donationUrl}
                    variant="primary"
                  />
                )}

                <Button
                  as={Link}
                  icon={faShareNodes}
                  label={t(`${baseTranslationString}.actions.share`)}
                  tone={tone}
                  to={`/${signable.name}/share`}
                  variant="outline"
                />

                <Button
                  as="a"
                  href={"initiative" == tone ? "/initiatives" : "#more"}
                  label={t(`${baseTranslationString}.actions.other.${tone}`)}
                  variant="outline"
                />
              </ButtonContainer>
            </div>
          ) : (
            <>
              <Visible when={isAuth && !signable.success}>
                <p>{t(`${baseTranslationString}.data.${tone}`)}</p>
              </Visible>
              <UserForm
                autoFocus="firstName"
                submitButtonLabel={
                  signedStatus === "notSigned"
                    ? (t(
                        `pages.signable.signForm.submitButtonLabel.${tone}`,
                      ) as string)
                    : (t(
                        "pages.signable.signForm.submitButtonLabel.subscribe",
                      ) as string)
                }
                loggedIn={isAuth}
                user={signer}
                setUser={setSigner}
                subscriptionText={{
                  true: t(
                    `pages.signable.signForm.subscribe.true.${tone}`,
                  ) as string,
                  false: t(
                    `pages.signable.signForm.subscribe.false.${tone}`,
                  ) as string,
                }}
                onSubmit={handleFormSubmit}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default SignForm;
