import { useContext, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { routes } from "@Configs";
import { PageTitleContext } from "@Contexts/PageTitleContext";
import { CSSObject } from "@emotion/react";
import { transition } from "@Molecules";
import {
  displayFlex,
  gap2xl,
  marginYMdZero,
  marginYXs2xl,
  paddingYMdXl,
  paddingYXs2xl,
} from "@Styles";
import { Page } from "@Types/services/post";
import api from "@Utils/api";
import DonorBanner from "./DonorBanner";
import Features from "./Features";
import HeadingParagraph from "./HeadingParagraph";
import Hero from "./Hero";
import Moderatos from "./Moderatos";
import SocialMediaBanner from "./SocialMediaBanner";

const AboutPage = () => {
  const [loaded, setLoaded] = useState(false);
  const navigate = useNavigate();
  const [page, setPage] = useState<Page>({} as Page);
  const { setPageTitle } = useContext(PageTitleContext);

  useEffect(() => {
    api
      .getPage("about")
      .then((response) => {
        const page = response.data;
        setPage(page);

        setPageTitle(page.title);
      })
      .catch(() => navigate(routes.error))
      .finally(() => setLoaded(true));
  }, [navigate, setPageTitle]);

  const spacing: CSSObject[] = [marginYXs2xl, marginYMdZero];

  return (
    <section css={[displayFlex, gap2xl, { flexDirection: "column" }]}>
      <Hero
        loaded={loaded}
        page={page}
      />

      <HeadingParagraph
        css={spacing}
        loaded={loaded}
        page={page}
      />

      <Features
        css={spacing}
        loaded={loaded}
        page={page}
      />

      <DonorBanner
        css={spacing}
        loaded={loaded}
        page={page}
      />

      <Moderatos
        loaded={loaded}
        page={page}
        css={spacing}
      />

      <SocialMediaBanner
        css={[paddingYXs2xl, paddingYMdXl]}
        loaded={loaded}
        page={page}
      />
    </section>
  );
};

export default transition(AboutPage);
