import { CSSObject } from "@emotion/react";
import { bgTransparent, displayFlex } from "@Styles";
import { FramerDivisionProps } from "@Types/components/pageProps";
import { color } from "@Utils";
import linearGradient from "@Utils/colorGradient";
import colors from "@Variables/colors";
import { motion } from "framer-motion";

const containerStyles: CSSObject[] = [
  bgTransparent,
  displayFlex,
  { flexDirection: "column", width: "100%", gap: "0.2em" },
];

type ShimmerProps = FramerDivisionProps & {
  /** Number of lines to be displayed. */
  lines?: number;
};

/**
 * An element to be displayed inside other block elements while the content is being loaded.
 */
const Shimmer = ({ lines = 1, ...props }: ShimmerProps) => {
  if (lines < 1) lines = 1;

  const lineStyle: CSSObject = {
    display: "inline-block", // Make sure it's block to fill the width
    width: "100%",

    backgroundImage: linearGradient(
      color.hexToRgba(colors.gray3, 0.6),
      color.hexToRgba(colors.gray1, 0.8),
    ),
    backgroundSize: "200% 100%",
    backgroundPosition: "100% 0",
    position: "relative", // Ensure it's positioned
    overflow: "hidden",
    borderRadius: "0.25rem",
    color: "transparent",
  };

  const lastLineStyle: CSSObject = {
    width: "65%", // Make the last line shorter for a more natural effect
  };

  // Define animation variants
  const shimmerVariants = {
    animate: {
      backgroundPosition: ["100% 0", "0 0", "100% 0"],
      transition: {
        duration: 2,
        ease: "linear",
        repeat: Infinity,
      },
    },
  };

  return (
    <motion.div css={containerStyles}>
      {[...Array(lines)].map((_, index) => {
        const isLastLine = index > 1 && index === lines - 1;
        return (
          <motion.div
            css={[lineStyle, isLastLine && lastLineStyle]}
            variants={shimmerVariants} // Keyframe-based animation
            animate="animate"
            key={index}
            {...props}
          >
            &nbsp;
          </motion.div>
        );
      })}
    </motion.div>
  );
};

export default Shimmer;
