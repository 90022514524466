import { useContext, useEffect, useState } from "react";

import { CSSObject } from "@emotion/react";
import parse from "html-react-parser";
import { useParams } from "react-router-dom";

import { Column, Container, Heading, Placeholder, Row } from "@Components";
import { PageTitleContext } from "@Contexts/PageTitleContext";
import {
  colorChildren,
  colorPrimary,
  marginBottomXl,
  marginTopLg,
} from "@Styles";
import { Page } from "@Types/services/post";
import { transition } from "@Molecules";
import Error from "./Error";
import { PostsService } from "@Services/posts.service";

const PagePage = () => {
  const postsService = PostsService.getInstance();
  const [page, setPage] = useState<Page>({ title: "", content: "" } as Page);
  const [hasError, setHasError] = useState<boolean>(false);
  const { setPageTitle } = useContext(PageTitleContext);

  const { _id } = useParams();

  const loadPage = async () => {
    if (!_id) {
      return setHasError(true);
    }

    try {
      const { data } = await postsService.getPage(_id);
      setPage(data);
    } catch {
      setHasError(true);
    }
  };

  useEffect(() => {
    loadPage();
  }, [_id]);

  useEffect(() => {
    setPageTitle(page.title);
  }, [page, setPageTitle]);

  const containerCSS: CSSObject[] = [marginTopLg, marginBottomXl];

  const titleCSS: CSSObject[] = [colorPrimary("initiative")];

  const contentCSS: CSSObject[] = [colorChildren("initiative")];

  if (hasError) {
    return <Error />;
  }

  return (
    <Container size="lg">
      <Row>
        <Column
          span={{ default: 12, md: 10, lg: 6 }}
          offset={{ md: 1, lg: 3 }}
        >
          <div css={containerCSS}>
            <div>
              <Heading
                scale={3}
                aria-level={1}
                css={titleCSS}
              >
                {page.title ? page.title : <Placeholder />}
              </Heading>
              <div css={contentCSS}>
                {page.content ? parse(page.content) : <Placeholder lines={3} />}
              </div>
            </div>
          </div>
        </Column>
      </Row>
    </Container>
  );
};

export default transition(PagePage);
