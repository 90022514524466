import { useTheme } from "@emotion/react";

import { headerStyle } from "@Appearances/pages/styleHomeSignables";
import {
  CardColumn,
  Container,
  Heading,
  Row,
  SignableCard,
  Tab,
  Tabs,
} from "@Components";
import { PAGINATION_LIMIT } from "@Configs/constants";
import { useBreakpoints } from "@Hooks/useBreakpoints";
import { usePagiSignables } from "@Hooks/useSignable";
import { Backdrop, Pagination, SlidePagination } from "@Molecules";
import { marginTopZero } from "@Styles";
import type { SharedHomeProps, SignableType } from "@Types";
import type { SignableParams as SignParams } from "@Types/services/typeServiceParams";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

type SignVariantValue = SignParams & { variant: SignableType };

const limit = PAGINATION_LIMIT;

const Signables = (props: SharedHomeProps) => {
  //#region Functionality

  const { tone } = useTheme();

  const { t } = useTranslation(undefined, {
    keyPrefix: "pages.home.signables",
  });

  const { isSmallScreen } = useBreakpoints();

  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page: number) => setCurrentPage(page);

  //#region signVariants
  const signVariants = useMemo(
    (): { [key in SignableType]: SignVariantValue } => ({
      highlights: {
        highlight: true,
        limit,
        offset: limit * (currentPage - 1),
        type: tone,
        variant: "highlights",
        sort: null,
      },
      mostSigned: {
        type: tone,
        limit,
        offset: limit * (currentPage - 1),
        sort: "signatureCount",
        variant: "mostSigned",
      },
      successes: {
        type: tone,
        limit,
        offset: limit * (currentPage - 1),
        success: true,
        variant: "successes",
        sort: null,
      },
    }),
    [currentPage, tone],
  );
  //#endregion signVariants

  const [activeType, setActiveType] = useState<SignableType>("highlights");

  const activeSign = useMemo(
    () => signVariants[activeType],
    [activeType, currentPage, tone],
  );

  const { variant, ...params } = activeSign;

  const { meta, signables, loading } = usePagiSignables({
    params,
    variant,
    tone,
  });

  const handleTabClick = (type: SignableType = "highlights") => {
    setActiveType(type);
  };

  return (
    <section {...props}>
      <Container size="lg">
        <div css={headerStyle}>
          <Heading
            scale={3}
            color="label"
            role="heading"
            aria-level={2}
            css={marginTopZero}
          >
            {t(`${tone}Title`)}
          </Heading>
        </div>

        <Tabs<SignableType> toolbar={<></>}>
          {tabList.map(({ key, label, path }) => {
            return (
              <Tab
                key={key}
                onTabChange={handleTabClick}
                id={key}
                label={t(label)}
                active={activeType === key}
                path={path}
              >
                {isSmallScreen ? (
                  <SlidePagination
                    meta={meta}
                    signables={signables}
                    onPageChange={handlePageChange}
                    loading={loading && activeType === key}
                  />
                ) : (
                  <Backdrop visible={loading}>
                    <Row>
                      {signables?.map((signable) => {
                        return (
                          <CardColumn
                            key={`${key}-${signable._id}`}
                            span={{ default: 12, sm: 6, md: 4 }}
                          >
                            <SignableCard signable={signable} />
                          </CardColumn>
                        );
                      })}
                    </Row>

                    <Pagination
                      loading={loading}
                      current={currentPage}
                      meta={meta}
                      onPageChange={handlePageChange}
                    />
                  </Backdrop>
                )}
              </Tab>
            );
          })}
        </Tabs>
      </Container>
    </section>
  );
};

export default Signables;

const tabList: {
  key: SignableType;
  label: string;
  path: string;
}[] = [
  {
    key: "highlights",
    label: "tabs.highlights",
    path: "#highlights",
  },
  {
    key: "mostSigned",
    label: "tabs.mostSigned",
    path: "#mostSigned",
  },
  {
    key: "successes",
    label: "tabs.successes",
    path: "#successes",
  },
];
