import { useEffect, useState } from "react";

import {
  faCircleExclamation,
  faTrashCheck,
} from "@fortawesome/pro-regular-svg-icons";
import { useTranslation } from "react-i18next";

import ModalDialog from "../../emotion/components/ModalDialog";
import { useSignatureContext } from "@Contexts/SignatureContext";
import { useModalContext } from "@Contexts/ModalsContext";
import { fontSizeXl } from "@Styles/fontSizes";
import { breakpointUp } from "@Variables/breakpoints";
import { ButtonProps } from "@Components/Button";

const DeleteSignatureModal = () => {
  const { t } = useTranslation();
  const { setSignature } = useSignatureContext();
  const { openModal, toggleModal, modalProps } = useModalContext();
  const [modalContentState, setModalContentState] = useState<
    "beforeDelete" | "afterDelete"
  >("beforeDelete");
  // Use useEffect to reset modal state when it is closed

  const onDelete = modalProps.onDelete; // Extract the onDelete callback

  useEffect(() => {
    if (openModal !== "DeleteSignatureModal") {
      setModalContentState("beforeDelete");
    }
  }, [openModal]);

  const buttons: {
    action?: ButtonProps["onClick"];
    label: ButtonProps["label"];
    loading?: ButtonProps["loading"];
    type?: ButtonProps["type"];
    variant: ButtonProps["variant"];
  }[] =
    modalContentState === "beforeDelete"
      ? [
          {
            action: () => {
              toggleModal("DeleteSignatureModal");
            },
            label: t(
              "pages.userAccount.signatures.modal.DeleteSignature.cancel.label",
            ),
            variant: "outline",
            type: "button",
          },
          {
            action: async () => {
              try {
                setSignature({
                  isDone: true,
                  signatureId: "",
                });

                // Call the onDelete callback to update the parent component
                if (onDelete) {
                  onDelete();
                }

                setModalContentState("afterDelete");
              } catch (error) {
                console.error("Failed to delete signature:", error);
              }
            },
            label: t(
              "pages.userAccount.signatures.modal.DeleteSignature.deleteNow.label",
            ),
            variant: "primary",
            type: "button",
          },
        ]
      : [
          {
            action: () => {
              toggleModal("DeleteSignatureModal");
            },
            label: t(
              "pages.userAccount.signatures.modal.DeleteSignature.close.label",
            ),
            variant: "primary",
            type: "button",
          },
        ];
  return (
    <ModalDialog
      buttons={buttons}
      titleCSS={{
        flexDirection: "row",
        alignItems: "center",
        [breakpointUp["md"]]: {
          ...fontSizeXl,
        },
      }}
      buttonContainerProps={{
        align: {
          default: modalContentState === "beforeDelete" ? "justify" : "right",
          sm: modalContentState === "beforeDelete" ? "justify" : "right",
          md: modalContentState === "beforeDelete" ? "justify" : "right",
          lg: modalContentState === "beforeDelete" ? "justify" : "right",
        },
      }}
      content={<></>}
      id="DeleteSignatureModal"
      title={
        modalContentState === "beforeDelete"
          ? "Willst Du Deine Unterschrift zu der Petition wirklich löschen?"
          : "Deine Unterschrift wurde gelöscht."
      }
      icon={
        modalContentState === "beforeDelete"
          ? faCircleExclamation
          : faTrashCheck
      }
      iconColor="primary"
      titleColor="primary"
    ></ModalDialog>
  );
};

export default DeleteSignatureModal;
