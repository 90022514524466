import routes from "@Configs/routes";
import { UserFormFields } from "../components/UserForm";

type Paths = "general" | "petition" | "initiative";
type Source =
  | "header"
  | "error"
  | "homeBanner"
  | "signablePage"
  | "post_signature"
  | "footer";
type GetDonationUrlProps = {
  context: Paths;
  source: Source;
  signableId?: string;
  donor?: UserFormFields;
  amount?: number;
};

const getDonationUrl = ({
  context,
  source,
  signableId,
  donor,
  amount,
}: GetDonationUrlProps) => {
  const baseUrl = routes.organization;

  const PATHS = {
    general: "spenden",
    petition: "psf-petitionen",
    initiative: "psf-initiativen",
  };

  const hasParameter = source || signableId;
  const hasParameters = source && signableId;

  let hasDonorParams = false;
  let isFirstDonorParam = true;
  const donorParams = {
    first_name: "",
    last_name: "",
    email: "",
    post_code: "",
    city: "",
  };

  if (donor) {
    if (donor.firstName) {
      donorParams.first_name = donor.firstName;
      hasDonorParams = true;
    }
    if (donor.lastName) {
      donorParams.last_name = donor.lastName;
      hasDonorParams = true;
    }
    if (donor.email) {
      donorParams.email = donor.email;
      hasDonorParams = true;
    }
    if (donor.postalCodeDisplay) {
      donorParams.post_code = donor.postalCodeDisplay;
      hasDonorParams = true;
    }
    if (donor.locationDisplay) {
      donorParams.city = donor.locationDisplay;
      hasDonorParams = true;
    }
  }

  const url =
    baseUrl +
    PATHS[context] +
    (hasParameter ? "?" : "") +
    (source ? "donation_custom_field_2304=" + source : "") +
    (hasParameters ? "&" : "") +
    (signableId ? "donation_custom_field_8875=" + signableId : "") +
    (hasParameter && hasDonorParams ? "&" : "") +
    (!hasParameter && hasDonorParams ? "?" : "") +
    (hasDonorParams
      ? Object.keys(donorParams)
          .map((param) => {
            let string = "";
            if (donorParams[param as keyof typeof donorParams]) {
              string += isFirstDonorParam && !hasParameter ? "?" : "&";
              string +=
                param + "=" + donorParams[param as keyof typeof donorParams];
              isFirstDonorParam = false;
            }
            return string;
          })
          .join("")
      : "") +
    (amount ? `&amount=" + ${amount}` : "");

  return url;
};

export default getDonationUrl;
