import { useEffect, useState } from "react";

import parse from "html-react-parser";

import {
  Body,
  Column,
  Container,
  Placeholder,
  QandA,
  QandAItem,
  Row,
} from "@Components";
import { useTheme } from "@emotion/react";
import { FaqCategory } from "@Types/services/post";
import api from "@Utils/api";

export const ModerationQuestions = () => {
  const [loaded, setLoaded] = useState(false);
  const [categories, setCategories] = useState<FaqCategory[]>(
    [] as FaqCategory[],
  );
  const { tone } = useTheme();

  useEffect(() => {
    api
      .getFaqs()
      .then((response) => {
        const result = response.data;
        setCategories(result);
      })
      .catch(() => new Error(""))
      .finally(() => setLoaded(true));
  }, [tone]);

  return (
    <Container size="md">
      <Row>
        <Column>
          <QandA tone="petition">
            {loaded ? (
              categories?.map((category) => (
                <QandAItem
                  key={category.name}
                  question={category.title}
                >
                  {category?.faqs?.map((faq) => {
                    return <Body key={faq._id}>{parse(faq.answer)}</Body>;
                  })}
                </QandAItem>
              ))
            ) : (
              <Placeholder />
            )}
          </QandA>
        </Column>
      </Row>
    </Container>
  );
};
