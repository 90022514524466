import { CSSObject, useTheme } from "@emotion/react";
import { motion } from "framer-motion";

const slideStyles: CSSObject = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100vh",

  transformOrigin: "bottom",
};

const slideInStyles = (backgroundColor: string): CSSObject[] => [
  slideStyles,
  {
    transformOrigin: "bottom",
    backgroundColor,
  },
];

const slideOutStyles = (backgroundColor: string): CSSObject[] => [
  slideStyles,
  {
    transformOrigin: "top",
    backgroundColor,
  },
];

const transition = (OgComponent: any) => {
  function TransitionComponent() {
    const { colors } = useTheme();
    const color = colors.primary.main;

    return (
      <>
        <OgComponent />

        <motion.div
          css={slideInStyles(color)}
          initial={{ scaleY: 0 }}
          animate={{ scaleY: 0 }}
          exit={{ scaleY: 1 }}
          transition={{ duration: 1, ease: [0.22, 1, 0.36, 1] }}
        />

        <motion.div
          css={slideOutStyles(color)}
          initial={{ scaleY: 1 }}
          animate={{ scaleY: 0 }}
          exit={{ scaleY: 0 }}
          transition={{ duration: 1, ease: [0.22, 1, 0.36, 1] }}
        />
      </>
    );
  }

  return TransitionComponent;
};

export default transition;
