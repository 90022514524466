import parse from "html-react-parser";
import { useTranslation } from "react-i18next";

import {
  BodyLg,
  Column,
  Container,
  Heading,
  Placeholder,
  Row,
} from "@Components";
import { marginTopZero, marginZero, paddingXMdLg } from "@Styles/spacers";
import { PageFragmentProps } from "@Types/components/pageProps";
import type { Fragment } from "@Types/services/post";

const ModerationHeader = ({ loaded, page }: PageFragmentProps) => {
  const { t } = useTranslation();

  const fragments =
    loaded &&
    page?.fragments &&
    page?.fragments?.filter(
      (fragment: Fragment) => fragment?.section == "moderation-header-section",
    );
  const title = fragments ? t(fragments[0]?.title) : <Placeholder />;
  const content = fragments ? parse(t(fragments[0]?.content)) : <Placeholder />;

  return (
    <Container size="lg">
      <Row>
        <Column span={{ default: 12, sm: 12, md: 6, lg: 6 }}>
          <Heading
            scale={3}
            role="heading"
            aria-level={2}
            css={[marginTopZero, paddingXMdLg]}
          >
            {title}
          </Heading>
        </Column>

        <Column span={{ default: 12, sm: 12, md: 6, lg: 6 }}>
          <BodyLg css={[paddingXMdLg, marginZero]}>{content}</BodyLg>
        </Column>
      </Row>
    </Container>
  );
};

export default ModerationHeader;
