import { Button, Column, Container, Headline, Row, Tab } from "@Components";
import Visible from "@Components/atoms/Visible";
import routes from "@Configs/routes";
import { useUserContext } from "@Contexts/UserContext";
import type { CSSObject } from "@emotion/react";
import { faPlusLarge, faSpinner } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { transition } from "@Molecules";
import {
  displayFlex,
  marginBottomZero,
  marginTopSm,
  paddingBottomLg,
  paddingTopLg,
} from "@Styles";
import type { AdjustedSignableType } from "@Types/pages/typeUserProfile";
import type { Signable } from "@Types/services/post";
import { breakpointDown, breakpointUp } from "@Variables";
import themes from "@Variables/themes";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import MyPetitionsPage from "./MyPetitions";
import { fetchPetitions } from "./MyPetitions/fetchPetitions";
import MyProfilePage from "./MyProfile"; // Assuming MyProfilePage doesn't need dynamic data
import MySignaturesPage from "./MySignatures";
import { fetchSignatures } from "./MySignatures/fetchSignatures";

const UserProfile = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { user, isLoading } = useUserContext();

  const [active, setActive] = useState<string>("");
  const [component, setComponent] = useState<React.ReactNode>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const secondaryColor = themes["petition"].colors.secondary;

  const TAB_CONTENT = [
    {
      id: "petitions",
      label: t("pages.userAccount.tabs.myPetitions"),
      fetchData: () => fetchPetitions(0),
      component: (data: Signable[]) => <MyPetitionsPage initialData={data} />,
    },
    {
      id: "signatures",
      label: t("pages.userAccount.tabs.mySignatures"),
      fetchData: () => fetchSignatures(0),
      component: (data: AdjustedSignableType[]) => (
        <MySignaturesPage initialData={data} />
      ),
    },
    {
      id: "profile",
      label: t("pages.userAccount.tabs.myProfile"),
      component: <MyProfilePage />, // No fetchData here since it's static or preloaded
    },
  ];

  // Fetch data and set the component when a tab is activated
  const fetchAndSetComponent = async (tab: any) => {
    if (tab.fetchData) {
      setLoading(true);
      try {
        const data = await tab.fetchData(); // Fetch the data for the selected tab
        setComponent(tab.component(data)); // Set the component with fetched data
      } catch (error) {
        console.error("Error fetching data: ", error);
      } finally {
        setLoading(false);
      }
    } else {
      setComponent(tab.component); // Directly set the component if no fetch is needed
    }
  };

  useEffect(() => {
    const initialTab =
      TAB_CONTENT.find((tab) => location.pathname.endsWith(tab.id)) ||
      TAB_CONTENT[0];
    setActive(initialTab.id);
    fetchAndSetComponent(initialTab); // Fetch and set the initial tab's component
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const handleTabClick = (tab: any) => {
    setActive(tab.id);
    fetchAndSetComponent(tab);
    navigate(`/user/${tab.id}`);
  };

  const tabsContainerStyle: CSSObject = {
    ...displayFlex,
    alignItems: "center",
    borderBottom: `1px solid ${secondaryColor}`,
    borderTop: `1px solid ${secondaryColor}`,
    cursor: "pointer",
    height: "3.375rem",
    [breakpointUp["default"]]: {
      borderLeft: `1px solid ${secondaryColor}`,
      borderRadius: "2rem",
      marginBottom: "1rem",
    },
    [breakpointUp["lg"]]: {
      ":nth-of-type(2n - 1)": {
        borderLeft: `1px solid ${secondaryColor}`,

        borderRadius: "2rem 0 0 2rem",

        borderRight: "none",
      },

      ":nth-of-type(2n)": {
        borderLeft: "none",
        borderRadius: "0",
        borderRight: "none",
      },
      ":nth-of-type(2n + 1):last-child": {
        borderRadius: "0rem 2rem 2rem 0rem",
        borderRight: `1px solid ${secondaryColor}`,
        borderLeft: "none",
      },
    },
    [breakpointDown["lg"]]: {
      ":nth-of-type(2n - 1)": {
        borderLeft: `1px solid ${secondaryColor}`,
        borderRadius: "2rem 0rem 0rem 2rem",
        borderRight: "none",
      },
      ":nth-of-type(2n)": {
        borderLeft: "none",
        borderRadius: "0 2rem 2rem 0",
        borderRight: `1px solid ${secondaryColor}`,
      },
      ":nth-of-type(2n + 1):last-child": {
        borderRadius: "2rem",
        borderRight: `1px solid ${secondaryColor}`,
        borderLeft: `1px solid ${secondaryColor}`,
      },
    },
    [breakpointDown["md"]]: {
      ":nth-of-type(2n - 1)": {
        borderLeft: `1px solid ${secondaryColor}`,
        borderRadius: "2rem 0 0 2rem",
        borderRight: "none",
      },
      ":nth-of-type(2n)": {
        borderLeft: "none",
        borderRight: `1px solid ${secondaryColor}`,
      },
      ":nth-of-type(2n + 1):last-child": {
        borderRadius: "2rem",
        borderRight: `1px solid ${secondaryColor}`,
      },
    },
    [breakpointDown["xs"]]: {
      ":nth-of-type(2n - 1)": {
        borderLeft: `1px solid ${secondaryColor}`,
        borderRadius: "2rem 0 0 2rem",
        borderRight: "none",
      },
      ":nth-of-type(2n)": {
        borderLeft: "none",
        borderRight: `1px solid ${secondaryColor}`,
      },
      ":nth-of-type(2n + 1):last-child": {
        borderRadius: "2rem",
        borderRight: `1px solid ${secondaryColor}`,
      },
    },
  };

  const headerStyle: CSSObject = {
    ...paddingBottomLg,
    ...paddingTopLg,
  };

  const startPetitionButtonStyle: CSSObject = {
    display: "flex",
    [breakpointUp["default"]]: {
      justifyContent: "flex-start",
    },
    [breakpointUp["lg"]]: {
      justifyContent: "flex-end",
    },
  };

  return (
    <section>
      <Container size="lg">
        <Row>
          <Column>
            <Headline
              title={
                !isLoading && user && user?.firstName
                  ? t("pages.userAccount.greeting", {
                      firstName: user?.firstName,
                    })
                  : t("pages.userAccount.greeting", { firstName: "" })
              }
              titleCSS={headerStyle}
            />
          </Column>
        </Row>
        <Row>
          <Column span={{ default: 12, lg: 7, md: 12, sm: 12, xs: 12 }}>
            <Row css={{ marginRight: "0", marginLeft: "0" }}>
              {TAB_CONTENT.map((content, index) => (
                <Column
                  key={content.id}
                  css={[tabsContainerStyle, { padding: 0 }]}
                  span={{
                    default: index === TAB_CONTENT?.length - 1 ? 12 : 6,
                    lg: 4,
                    md: index === TAB_CONTENT?.length - 1 ? 12 : 6,
                    sm: index === TAB_CONTENT?.length - 1 ? 12 : 6,
                    xs: index === TAB_CONTENT?.length - 1 ? 12 : 6,
                  }}
                >
                  <Tab
                    active={active === content.id}
                    id={content.id}
                    label={content.label}
                    key={content.id}
                    onTabChange={() => handleTabClick(content)}
                    css={{ width: "100%" }}
                  />
                </Column>
              ))}
            </Row>
          </Column>
          <Column
            span={{ default: 12, lg: 5, md: 12, sm: 12, xs: 12 }}
            css={startPetitionButtonStyle}
          >
            <Visible when={active === "petitions"}>
              <Button
                icon={faPlusLarge}
                label={t("pages.userAccount.petition.startPetition.label")}
                onClick={() => navigate(routes.petition.start)}
                tone="petition"
                variant="transparent"
              />
            </Visible>
          </Column>
        </Row>
      </Container>
      <Container>
        {loading ? (
          <section
            css={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <FontAwesomeIcon
              icon={faSpinner}
              spin
              fixedWidth
              fontSize={30}
              aria-label={t("common.loading") as string}
            />
            <p css={{ ...marginBottomZero, ...marginTopSm }}>
              {t("common.loading") as string}
            </p>
          </section>
        ) : (
          component
        )}
      </Container>
    </section>
  );
};

export default transition(UserProfile);
