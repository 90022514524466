import { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";

import { transition } from "@Components/molecules";
import { Post } from "@Types/services/post";
import Error from "./Error";
import PagePage from "./PagePage";
import SignablePage from "./SignablePage";
import { PostsService } from "@Services/posts.service";

const PostRedirection = () => {
  const postsService = PostsService.getInstance();
  const [post, setPost] = useState<Post>({ title: "", content: "" } as Post);
  const [hasError, setHasError] = useState<boolean>(false);
  const navigate = useNavigate();

  const { _id } = useParams();

  const loadPost = async () => {
    if (!_id) {
      return setHasError(true);
    }

    try {
      const { data: post } = await postsService.getPost(_id);
      if ("update" == post.type) {
        return navigate(`/${post.parent}/updates/${post.name}`);
      }
      setPost(post);
    } catch {
      setHasError(true);
    }
  };

  useEffect(() => {
    loadPost();
  }, [_id, navigate]);

  if (hasError) {
    return <Error />;
  }

  return (
    <>
      {post.type === "page" && <PagePage />}
      {(post.type === "initiative" || post.type === "petition") && (
        <SignablePage />
      )}
    </>
  );
};

export default transition(PostRedirection);
