import { useContext, useEffect } from "react";

import { CSSObject } from "@emotion/react";
import { faChevronsLeft } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";

import {
  BannerIllustration,
  Button,
  Column,
  Container,
  Heading,
  Illustration,
  Row,
} from "@Components";
import { routes } from "@Configs";
import { PageTitleContext } from "@Contexts/PageTitleContext";
import { faBoxHeart } from "@fortawesome/pro-regular-svg-icons";
import { transition } from "@Molecules";
import { displayInlineFlex, paddingYXl } from "@Styles";
import getDonationUrl from "@Stylize/helpers/getDonationUrl";
import { Link } from "react-router-dom";

const ErrorPage = () => {
  const { t } = useTranslation();
  const { setPageTitle } = useContext(PageTitleContext);

  useEffect(() => {
    setPageTitle(t("pages.error.title") as string);
  }, []);

  const titleCSS: CSSObject[] = [
    {
      justifyContent: "center",
    },
  ];

  return (
    <>
      <Container
        size="lg"
        css={paddingYXl}
      >
        <Row css={{ flexDirection: "column" }}>
          <Column
            span={{ default: 12, md: 8 }}
            offset={{ default: 0, md: 2 }}
          >
            <Heading
              scale={2}
              variant="display"
              css={titleCSS}
              align="center"
            >
              {t("pages.error.title")}
            </Heading>
          </Column>

          <Column
            css={[
              paddingYXl,
              displayInlineFlex,
              {
                justifyContent: "center",
              },
            ]}
            span={{ default: 12, lg: 4, md: 4, sm: 6, xs: 4 }}
            offset={{ default: 0, lg: 4, md: 4, sm: 3 }}
          >
            <Illustration
              name="alert"
              size="lg"
            />
          </Column>

          <Column css={[displayInlineFlex, { justifyContent: "center" }]}>
            <Button
              variant="transparent"
              as={Link}
              color="primary"
              label={t("pages.error.link")}
              to={routes.root}
              icon={faChevronsLeft}
            />
          </Column>
        </Row>
      </Container>

      <BannerIllustration
        buttonLabel={t("common.banners.donation.buttonLabel")}
        buttonLink={getDonationUrl({
          context: "general",
          source: "error",
          signableId: "",
        })}
        content={t("common.banners.donation.content")}
        illustration="start"
        title={t("common.banners.donation.title")}
        icon={faBoxHeart}
      />
    </>
  );
};

export default transition(ErrorPage);
