import { useContext } from "react";

import { PageTitleContext } from "@Contexts/PageTitleContext";
import { type CSSObject } from "@emotion/react";
import usePage from "@Hooks/usePage";
import { transition } from "@Molecules";
import {
  HomeSignableAboutUs,
  HomeSignableDonation,
  HomeSignableHero,
  HomeSignableNewsletter,
  HomeSignableReasons,
  HomeSignableSuccesses,
  HomeSignables,
} from "@Organisms/Signables";
import { paddingY2xl } from "@Styles";

const customCss: CSSObject = paddingY2xl;

const Home = () => {
  const { setPageTitle } = useContext(PageTitleContext);
  const { page, loading } = usePage({
    id: "home",
    onError: (error) => console.error("An error occurred:", error),
    onSuccess: (data) => setPageTitle(data.title),
  });

  return (
    <>
      <HomeSignableHero
        loading={loading}
        page={page}
      />

      <HomeSignables css={customCss} />

      <HomeSignableAboutUs css={customCss} />

      <HomeSignableSuccesses css={customCss} />

      <HomeSignableDonation css={customCss} />

      <HomeSignableNewsletter css={customCss} />

      <HomeSignableReasons
        loaded={!loading}
        page={page}
        css={customCss}
      />
    </>
  );
};

export default transition(Home);
