import React, { useEffect, useState } from "react";

import { faEnvelopeDot } from "@fortawesome/pro-duotone-svg-icons";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import Visible from "@Atoms/Visible";
import Alert from "@Components/Alert";
import { Container } from "@Components/Container";
import { routes } from "@Configs";
import { useModalContext } from "@Contexts/ModalsContext";
import { useUserContext } from "@Contexts/UserContext";
import { transition } from "@Molecules";
import { paddingYXl } from "@Styles/spacers";
import { Initiative, InitiativeInput, Tag } from "@Types/services/post";
import ButtonsBar from "./components/ButtonsBar";
import FieldHelp from "./components/help/FieldHelp";
import Pagination from "./components/Pagination";
import StepComponent from "./steps/StepComponent";
import { FieldsWithHelp, ValidTarget } from "./utils/types";
import { validateStep } from "./utils/validate-step";
import { PostsService } from "@Services/posts.service";

const StartPetitionPage = () => {
  const defaultInitiative: InitiativeInput = {
    id: "",
    type: "petition",
    title: "",
    tag: "",
    initiators: [] as { user: string; role: string; displayName: string }[],
    targets: [],
    content: "",
    hashtag: "",
    name: "",
    optionalFields: {
      counter: "signature",
    },
    displayName: "",
    status: "draft",
  };

  const [currentStep, setCurrentStep] = useState(0);
  const [completedSteps, setCompletedSteps] = useState([] as number[]);
  const { _id: id } = useParams();
  const [initiative, setInitiative] = useState(defaultInitiative);
  const [isSaving, setIsSaving] = useState(false);
  const [formErrors, setFormErrors] = useState({} as Record<string, boolean>);

  const [status, setStatus] = useState(0);
  const [tags, setTags] = useState([] as Tag[]);
  const [fieldHelp, setFieldHelp] = useState<FieldsWithHelp | null>(null);

  const { isAuth, user } = useUserContext();
  const { toggleModal } = useModalContext();

  const isUserVerified = isAuth && user?.status === "verified";

  const navigate = useNavigate();

  const MAX_LENGTHS: Record<string, number> = {
    title: 200,
  };

  const MIN_LENGTHS: Record<string, number> = {
    content: 1000,
  };

  const [counter, setCounter] = useState({ title: 0 } as typeof MAX_LENGTHS);

  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const postsService = PostsService.getInstance();

  const loadTags = async () => {
    const response = await postsService.getTags({});
    const tags = response.data;
    setTags(tags && Array.isArray(tags) ? tags : []);
  };

  useEffect(() => {
    loadTags();
  }, []);

  const loadTheInitiative = async () => {
    if (!id) {
      setCurrentStep(1);
      return;
    }

    setIsSaving(true);
    try {
      const response = await postsService.getInitiative(id);
      const existingInitiative: Initiative = response.data;
      if (!existingInitiative.canEdit) {
        setStatus(404);
      }
      const initiative: InitiativeInput = {
        ...existingInitiative,
        id: existingInitiative._id,
        tag: existingInitiative.tag?._id || "",
        displayName: existingInitiative?.initiators?.[0]?.displayName,
        user: existingInitiative?.initiators?.[0]?.user,
        initiators: undefined,
      };
      setInitiative(initiative);
    } catch (error: any) {
      if (error?.response?.status === 404) {
        setStatus(404);
      } else {
        console.error(error);
      }
    } finally {
      const step = Number(searchParams.get("step") || 1);
      if (currentStep !== step) {
        setCurrentStep(step);
      }
      if (!searchParams.get("step")) {
        setSearchParams((prev) => ({
          ...prev,
          step: "1",
        }));
      }
      if (!id && initiative.id) {
        navigate(`${routes.petition.start}/${initiative.id}`);
      }
      setIsSaving(false);
    }
  };

  useEffect(() => {
    loadTheInitiative();
  }, [id]);

  useEffect(() => {
    if (
      isAuth &&
      initiative.initiators?.length === 0 &&
      user &&
      !initiative.user
    ) {
      setInitiative({
        ...initiative,
        displayName: `${user.firstName || ""} ${user.lastName || ""}`,
        user: user._id,
      });
    }
  }, [isAuth, initiative, user]);

  const handleChange = async (event: {
    target: {
      name: string;
      value:
        | string
        | { customOption?: boolean; name?: string; id: string }[]
        | object[];
    };
  }) => {
    const { name } = event.target;
    const value = event.target.value;

    if (MAX_LENGTHS[name]) {
      setCounter({
        ...counter,
        [name]: MAX_LENGTHS[name] - value.length,
      });
    }

    let initiativeValue = value;
    if (name === "targets" && Array.isArray(value)) {
      const validTargets: ValidTarget[] = [];

      for (const target of value) {
        if (
          "customOption" in target &&
          target?.customOption &&
          "name" in target &&
          target?.name
        ) {
          const { data } = await postsService.createActor({
            name: target.name,
          });
          validTargets.push(data);
        } else if (
          "id" in target &&
          target.id &&
          !validTargets.some(
            (validTarget) =>
              "id" in validTarget && validTarget?.id === target.id,
          )
        ) {
          validTargets.push(target as { id: string });
        }
      }
      initiativeValue = validTargets;
    }

    setInitiative((prev) => ({ ...prev, [name]: initiativeValue }));

    setFormErrors((prev) => ({ ...prev, [name]: false }));
  };

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const files = (event.target as HTMLInputElement).files || [];
    if (!files[0]) {
      return;
    }
    setIsSaving(true);
    const formData = new FormData();
    formData.append("file", files[0]);
    formData.append("parent", initiative.id);
    try {
      const response = await postsService.createAttachment(formData);
      initiative.featuredImage = response.data;
      return save();
    } catch (error) {
      console.error(error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleNextStep = async (step: number) => {
    if (!isUserVerified) {
      toggleModal("getUser");
      return;
    }

    const { isValid, errors } = validateStep(
      currentStep,
      initiative,
      "petition-starten",
      MIN_LENGTHS,
      MAX_LENGTHS,
    );

    setFormErrors({ ...formErrors, ...errors });

    if (isValid) {
      try {
        if (currentStep !== 0) {
          await save();
        }

        setCompletedSteps((prev) => [...prev, currentStep]);
        setSearchParams((prev) => ({
          ...prev,
          step: step || Number(prev.get("step") || 1),
        }));
        setCurrentStep((prev) => step || prev + 1);
        setFieldHelp(null);
      } catch (error) {
        console.error("could not save", error);
      }
    }
  };

  const handlePreviousStep = (step?: number) => {
    setCurrentStep((prev) => {
      return step || prev - 1;
    });
    setSearchParams((prev) => ({
      ...prev,
      step: step || Number(prev.get("step") || 1),
    }));
    setFieldHelp(null);
  };

  const handleSubmit = async (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => {
    event.preventDefault();

    const { isValid, errors } = validateStep(
      currentStep,
      initiative,
      routes.petition.start,
      MIN_LENGTHS,
      MAX_LENGTHS,
    );

    if (isValid) {
      try {
        await save();
        setFieldHelp(null);
        navigate(`/${initiative.id}`, {
          state: { ...location.state, action: "draft", initiative },
        });
      } catch (error) {
        console.error(error);
        alert("Could not update");
      }
    } else {
      setFormErrors({ ...formErrors, ...errors });
    }
  };

  const save = async (params = {}) => {
    setIsSaving(true);

    const upsertInitiativeData = {
      ...initiative,
      featuredImage: initiative.featuredImage?.id,
      initiators: initiative.displayName
        ? [
            {
              user: initiative.user,
              displayName: initiative.displayName,
            },
          ]
        : initiative.initiators,
      targets:
        initiative.targets &&
        initiative.targets.map((target) => ({
          ...target,
          _id: target.id,
        })),
    };

    const response = await postsService.upsertInitiative(initiative.id, {
      ...upsertInitiativeData,
      ...params,
    });
    const { data } = response;
    const newInitiativeInput: InitiativeInput = {
      ...data,
      displayName: data?.initiators?.[0]?.displayName,
      user: data?.initiators?.[0]?.user,
      tag: data?.tag || "",
    };
    setInitiative(newInitiativeInput);
    setIsSaving(false);
  };

  useEffect(() => {
    if (!id && initiative.id) {
      navigate(`${routes.petition.start}/${initiative.id}?step=2`);
    }
  }, [initiative.id]);

  useEffect(() => {
    const newStep = Number(searchParams.get("step") || 1);
    if (newStep !== currentStep && currentStep !== 0) {
      setCurrentStep(newStep);
    }
    if (!id && initiative.id) {
      navigate(`${routes.petition.start}/${initiative.id}?step=${newStep}`);
    }
  }, [searchParams]);

  const hasPreviousStep = currentStep > 1;

  if (status === 404) {
    navigate(routes.error);
  }

  return (
    <article css={{ position: "relative" }}>
      <Visible when={isAuth && !isUserVerified}>
        <Alert
          icon={faEnvelopeDot}
          title="E-Mail-Bestätigung erforderlich"
          text={
            "description: Um eine Petition zu starten, bestätige bitte Deine E-Mail-Adresse mithilfe des zugesandten Bestätigungslinks. Falls du die E-Mail nicht erhalten hast, überprüfe bitte deinen Spam- oder Junk Ordner. Für Hilfe kontaktiere bitte unser Support-Team."
          }
          iconColor="white"
        />
      </Visible>

      {fieldHelp && (
        <FieldHelp
          fieldName={fieldHelp}
          setFieldHelp={setFieldHelp}
        />
      )}

      <Container size="md">
        <Container
          size="fw"
          css={paddingYXl}
        >
          <Visible when={isUserVerified}>
            <Pagination
              currentStep={currentStep}
              handleNextStep={handleNextStep}
              completedSteps={completedSteps}
              handlePreviousStep={handlePreviousStep}
              isSaving={isSaving}
            />
          </Visible>

          <Visible when={currentStep}>
            <form className="mt-6">
              <StepComponent
                counter={counter}
                formErrors={formErrors}
                handleNextStep={handleNextStep}
                handleChange={handleChange}
                handleImageUpload={handleImageUpload}
                initiative={initiative}
                isSaving={isSaving}
                maxLengths={MAX_LENGTHS}
                minLengths={MIN_LENGTHS}
                save={save}
                step={currentStep}
                tags={tags}
                setFieldHelp={setFieldHelp}
              />

              <Container css={paddingYXl}>
                <ButtonsBar
                  currentStep={currentStep}
                  handleNextStep={handleNextStep}
                  handlePreviousStep={handlePreviousStep}
                  handleSubmit={handleSubmit}
                  hasPreviousStep={hasPreviousStep}
                  isSaving={isSaving}
                  signableStatus={initiative.status}
                />
              </Container>
            </form>
          </Visible>
        </Container>
      </Container>
    </article>
  );
};

export default transition(StartPetitionPage);
