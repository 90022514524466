import { CSSObject } from "@emotion/react";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";

import { Container, Heading, Hero } from "@Components";
import { Shimmer } from "@Molecules";
import {
  calculateFontSize,
  marginBottomZero,
  paddingXZero,
  paddingY3xl,
  rowGapLg,
} from "@Styles";
import { Page } from "@Types/services/post";
import { breakpointDown } from "@Variables/breakpoints";

const heroStyle: CSSObject[] = [paddingY3xl, paddingXZero, marginBottomZero];

const containerStyle: CSSObject[] = [
  rowGapLg,
  {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
];

const minFontSize = 48.73;
const maxFontSize = 95.37;

const fontSize = calculateFontSize(minFontSize, maxFontSize);

const headingStyle: CSSObject = {
  fontSize,
};

type AboutUsHeroProps = {
  loaded: boolean;
  page: Page;
};

const AboutUsHero = ({ loaded, page }: AboutUsHeroProps) => {
  const { t } = useTranslation();

  return (
    <section>
      <Hero
        customStyles={heroStyle}
        bgCustomStyles={{
          [breakpointDown["sm"]]: {
            height: "75%",
            bottom: "10%",
          },
        }}
      >
        <Container css={containerStyle}>
          <Heading
            css={headingStyle}
            scale={1}
            role="heading"
            variant="display"
            color="white"
            align="center"
          >
            {loaded ? parse(t(page?.content)) : <Shimmer />}
          </Heading>
        </Container>
      </Hero>
    </section>
  );
};

export default AboutUsHero;
